const environment = {
  trustPlaceApi: {
    baseUrl: process.env.REACT_APP_TRUST_PLACE_API_BASE_URL,
  },
  ipApi: {
    baseUrl: process.env.REACT_APP_IP_API_BASE_URL,
    accessKey: process.env.REACT_APP_IP_API_ACCESS_KEY,
  },
};

export default environment;
